let initState={}

const brandsReducer = (state = initState, action) => {
    let copy;
    let index;
    switch (action.type) {
        case "SET_BRANDS":
            return {...state, brands: action.payload}
        
        case "ADD_BRAND":
            copy= JSON.parse(JSON.stringify(state.brands))
            copy.push(action.payload)
            return {...state, brands: copy}

        case "DELETE_BRAND":
            copy= JSON.parse(JSON.stringify(state.brands))
            copy.forEach((element, i) => {
                if(element.id===action.payload){
                    index = i;
                }
            });
            copy.splice(index, 1);
            return {...state, brands: copy}

        case "UPDATE_BRAND":
            copy = JSON.parse(JSON.stringify(state.brands))
            copy.forEach((element, i) => {
                if(element.id===action.payload.id){
                    index = i;
                }
            });
            copy.splice(index, 1, action.payload);
            return {...state, brands: copy}
    
        default : 
        return {...state}
        }
}

export default brandsReducer;