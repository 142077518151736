let initState={}

const badgesReducer = (state = initState, action) => {
    let copy;
    let index;
    switch (action.type) {
        case "SET_BADGES":
            return {...state, badges: action.payload}
        
        case "ADD_BADGE":
            copy= JSON.parse(JSON.stringify(state.badges))
            copy.push(action.payload)
            return {...state, badges: copy}

        case "DELETE_BADGE":
            copy= JSON.parse(JSON.stringify(state.badges))
            copy.forEach((element, i) => {
                if(element.id===action.payload){
                    index = i;
                }
            });
            copy.splice(index, 1);
            return {...state, badges: copy}

        case "UPDATE_BADGE":
            copy = JSON.parse(JSON.stringify(state.badges))
            copy.forEach((element, i) => {
                if(element.id===action.payload.id){
                    index = i;
                }
            });
            copy.splice(index, 1, action.payload);
            return {...state, badges: copy}
    
        default : 
        return {...state}
        }
}

export default badgesReducer;