// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
// import chatReducer from "./chatReducer";
import languageReducer from "./languageReducer/languageReducer"
import languageReducerDialog from "./languageReducer/languageReducerDialogs" 
import productsReducer from "./products/productReducer"
import categoryReducer from "./categories/categoryReducer"
import brandsReducer from "./brandReducer/brandReducer"
import badgesReducer from "./badgeReducer/badgeReducer"
import redirectReducer from "./redirectReducer/redirectReducer"
import containerReducer from "./containerReducer/containerReducer"
import cart from "./carts/reducer"

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   // calender: calenderReducer,
   // emailApp: emailReducer,
   // contactApp: contactsReducer,
   // todoApp: todoReducer,
   toastr: toastrReducer, // <- Mounted at toastr.
   // chatApp: chatReducer,
   // customizer: customizer,
   languageReducer: languageReducer,
   productsReducer: productsReducer,
   categoryReducer: categoryReducer,
   brandsReducer: brandsReducer,
   badgesReducer: badgesReducer,
   redirectReducer: redirectReducer,
   containerReducer: containerReducer,
   languageReducerDialog: languageReducerDialog,
   cart,
});

export default rootReducer;
