
let initState={redirect:true}


const redirectReducer = (state = initState, action) => {

    switch (action.type) {
        case "setRedirectBool":
            return {redirect: !state.redirect}
    
        default : 
        return {...state}
        }
}

export default redirectReducer;