
let initState={flag:"us"}


const languageReducer = (state = initState, action) => {

    switch (action.type) {
        case "CHANGE_FLAG":
            return {...state,flag: action.payload}
    
        default : 
        return {...state}
        }
}

export default languageReducer;