
// import loadData from "../../../components/addcategory/helpers/loadData"
// import {SET_PRODUCTS} from "../../actions/productAction/productsAction"
// import { useSelector, useDispatch } from "react-redux"

let initState={}

// const dispatch = useDispatch()


const productsReducer = (state = initState, action) => {
    let copy;
    let index;
    switch (action.type) {
        case "SET_PRODUCTS":
            return {...state, products: action.payload}
        
        case "ADD_PRODUCT":
            copy= JSON.parse(JSON.stringify(state.products))
            copy.push(action.payload)
            return {...state, products: copy}

        case "DELETE_PRODUCT":
            copy= JSON.parse(JSON.stringify(state.products))
            copy.forEach((element, i) => {
                if(element.id===action.payload){
                    index = i;
                }
            });
            copy.splice(index, 1);
            return {...state, products: copy}

        case "UPDATE_PRODUCT":
            copy = JSON.parse(JSON.stringify(state.products))
            copy.forEach((element, i) => {
                if(element.id===action.payload.id){
                    index = i;
                }
            });
            copy.splice(index, 1, action.payload);
            return {...state, products: copy}
    
        default : 
        return {...state}
        }
}

export default productsReducer;