let initialState = {
    count: 0,
    products: [],
    cartProductsfiltereds:[]
}

export default function cartsReducer(state=initialState, action){
    switch (action.type) {
        case "CARTS_PRODUCTS_COUNT":
            return {...state, count: action.payload}
        case "CARTS_PRODUCTS_ADD":
            return {...state, products: state.products.push(action.payload)}
        case "CARTS_PRODUCTS_SET":
            return {...state, products: action.payload}
            case "CARTS_PRODUCTS_FILTEREDS_SET":
                return {...state, cartProductsfiltereds: action.payload}
        default:
            return {...state}
    }
}