
// import loadData from "../../../components/addcategory/helpers/loadData"
// import {SET_category} from "../../actions/productAction/categoryAction"
// import { useSelector, useDispatch } from "react-redux"

let initState={}

// const dispatch = useDispatch()


const categoryReducer = (state = initState, action) => {
    let copy;
    let index;
    switch (action.type) {
        case "SET_CATEGORIES":
            // console.log(action.payload, "categoryyyy action.payload in redux")
            // console.log("categoryyyy action.payload in redux")
            return {...state, category: action.payload}
        
        case "ADD_CATEGORY":
            copy = JSON.parse(JSON.stringify(state.category))
            copy.push(action.payload)
            return {...state, category: copy}

        case "DELETE_CATEGORY":
            copy= JSON.parse(JSON.stringify(state.category))
            copy.forEach((element, i) => {
                if(element.id===action.payload){
                    index = i;
                }
            });
            copy.splice(index, 1);
            return {...state, category: copy}

        case "UPDATE_CATEGORY":
            copy = JSON.parse(JSON.stringify(state.category))
            copy.forEach((element, i) => {
                if(element.id===action.payload.id){
                    index = i;
                }
            });
            copy.splice(index, 1, action.payload);
            return {...state, category: copy}
    
        default : 
        return {...state}
        }
}

export default categoryReducer;