
let initState={flagDialogs:"us"}


const languageReducerDialogs = (state = initState, action) => {

    switch (action.type) {
        case "CHANGE_FLAG_DIALOGS":
            return {...state,flagDialogs: action.payload}
    
        default : 
        return {...state}
        }
}

export default languageReducerDialogs;